import React from 'react'

const list = [
  {
    name: 'Confluence Documentation',
    link: 'https://confluence.nike.com/display/NADE/Setting+up+Search+App+and+API+in+New+Waffle+Iron+AWS+Account'
  },
  {
    name: 'Architecture Diagrams',
    link: 'https://nike.ent.box.com/file/813260036957'
  },
  {
    name: 'Nike Developer Portal',
    link: 'https://developer.niketech.com/apps/nike.niketech.namerchmarketplaceadopt'
  },
  {
    name: 'Web App URL: (https://www.na-eda-api-test.nikecloud.com/)',
    link: 'https://www.na-eda-api-test.nikecloud.com/'
  },
  {
    name: 'API URL',
    link: 'https://api.na-eda-api-test.nikecloud.com/'
  },
  {
    name: 'Github Repository ( https://github.com/nike-na-cia/microservice-api )',
    link: 'https://github.com/nike-na-cia/microservice-api'
  },
  {
    name: 'BMX Pipeline',
    link: 'https://bmx-nampa.jenkins.bmx.nikecloud.com/view/NA%20EDA%20Marketplace/'
  },
  {
    name: 'How to reuse the API framework for another dataset?',
    link: 'https://confluence.nike.com/pages/viewpage.action?spaceKey=NADE&title=How+To+Reuse+the+API+Framework+for+New+Dataset'
  }
]
const AboutPage = () => (
  <div>
    <div className="ncss-container mb20-sm fixed-fluid body-3">
      <h3 className="headline-4 page-title text-color-light-grey">
          North America Merch Marketplace Adopt Application</h3>
      <br></br>
      <p>
      This application is created to search bookings and other marketplace details which are consolidated in an elastic search cluster.
      </p>
      <p>
      The search is performed by integrating with the rest API which is created as part of this project.
      </p>
      <p className="body-3">The web app and API is secured using Okta User Authentication and the API access is authorized using API Key.</p>
      <br></br>
      <ul className="ncss-container ncss-list-ol">
        {list.map(l => (<li> <a
          href={l.link }
          target="_blank"
          rel="noopener noreferrer">{l.name}</a></li>))}
      </ul>
    </div>

  </div>
)

export default AboutPage
