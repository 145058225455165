
import React, { useRef, useState, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { AllCommunityModules } from 'ag-grid-community'
import { toast } from 'react-toastify'

import { titleCase } from '../../util/stringUtil'
import config from '../../config'
import http from '../../util/http'
import { dataPageSize } from '../../constants'

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'

const DataGrid = ({ query, headers }) => {
  // eslint-disable-next-line no-unused-vars
  const [gridApi, setGridApi] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [gridColumnApi, setGridColumnApi] = useState(null)

  const [searchQuery, setSearchQuery] = useState()
  const [totalCount, setTotalCount] = useState(0)
  const [currentCount, setCurrentCount] = useState(0)

  useEffect(() => {
    setSearchQuery(undefined)
    setTotalCount(0)
    setCurrentCount(0)
    setTimeout(() => {
      setSearchQuery(query)
    }, (1))
  }, [query])

  const defaultColDef = {
    resizable: true,
    width: 200,
    editable: false,
    enableRangeSelection: true,
    enableCellTextSelection: true
  }
  const columnDetails = (headers) ? headers.map(col => ({ headerName: titleCase(col), field: col })) : {}
  const gridApiRef = useRef()

  function onGridReady (params) {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
    gridApiRef.current = params.api

    var dataSource = {
      rowCount: null,
      getRows: function (params) {
        console.log('requesting for ' + params.startRow + ' to ' + params.endRow)
        searchQuery.from = params.startRow
        searchQuery.size = dataPageSize
        searchQuery.shouldTransform = true
        http.get(`${config.apiUrl}/${config.searchIndex}`, searchQuery).then(({ results, total }) => {
          if (!results || results.length === 0) {
            toast('No results available!', {
              type: 'info',
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            })
          }
          setTotalCount(total)
          let lastRow = -1
          if (results.length < dataPageSize) {
            lastRow = total
            setCurrentCount(total)
          } else {
            setCurrentCount(params.endRow)
          }
          params.successCallback(results, lastRow)
        }).catch(e => {
          console.error(e)
          toast(e.message, {
            type: 'error',
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          })
        })
      }
    }
    params.api.setDatasource(dataSource)
  }

  const GridExportCSV = () => {
    if (currentCount < totalCount) {
      toast('Please scroll all the way to the bottom of the results to download entire search results!', {
        type: 'warning',
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      })
    }
    const params = {
      allColumns: true,
      columnGroups: false,
      skipHeader: false,
      skipPinnedTop: false,
      skipPinnedBottom: false,
      fileName: 'bookings.csv'
    }
    gridApiRef.current.exportDataAsCsv(params)
  }

  return (
    <div >
      <div className="bg-white b-r-5 ">
        {searchQuery && <div className="ag-theme-alpine ncss-container" style={{ height: window.innerHeight - 230 }} >
          <div className="ncss-container p3-sm pb5-s">
            <div className="ncss-row">
              <div className="ncss-col-sm-6 ncss-col-md-6 ta-sm-r pt3-sm">
                <h3 className="headline-4 fl-sm-l fl-md-l">Booking Details</h3>
              </div>
              <div className="ncss-col-sm-offset-3 ncss-col-sm-2 ncss-col-md-2 ta-sm-r pt3-sm">
                {totalCount > 0 && <span>{currentCount} out of {totalCount} </span>}
              </div>
              <div className="ncss-col-sm-1 ncss-col-md-1 ta-sm-r pt3-sm">
                <button className="ncss-btn-primary-dark" onClick={() => GridExportCSV()} value="Download">Download</button>
              </div>
            </div>
          </div>
          <AgGridReact
            modules={AllCommunityModules}
            columnDefs={columnDetails}
            defaultColDef={defaultColDef}
            rowModelType={'infinite'}
            paginationPageSize={dataPageSize}
            cacheBlockSize={dataPageSize}
            animateRows={true}
            overlayLoadingTemplate={
              '<span className="ag-overlay-loading-center"><Spinner large/></span>'
            }
            onGridReady={onGridReady}>
          </AgGridReact>
        </div>}
      </div>
    </div>
  )
}

export default DataGrid
