import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Multiselect } from 'multiselect-react-dropdown'
import { toast } from 'react-toastify'

import './Search.css'

const Search = ({ onSearch, options }) => {
  const [custName, setCustName] = useState('')
  const [productYearSeason, setProductYearSeason] = useState('')
  const [productDivision, setProductDivision] = useState('')
  const [gender, setGeder] = useState('')
  const [coreFocusShortDesc, setcoreFocusShortDesc] = useState('')

  const {
    custName: customerOptions = [],
    productYearSeason: productYSOptions = [],
    productDivision: divisionOptions = [],
    gender: genderOptions = [],
    coreFocusShortDesc: coreFocusOptions = []
  } = options || {}
  const { register, handleSubmit, clearErrors, formState: { errors } } = useForm()

  const onSubmit = () => {
    // TODO: Add additional validation if required
    if (custName.length > 0 && productYearSeason.length > 0 && productDivision.length > 0) {
      // Once validated generating data object and pass it to parent for further processing.
      const params = {
        custName,
        productYearSeason,
        productDivision
      }
      if (gender !== '') {
        params.gender = gender
      }
      if (coreFocusShortDesc !== '') {
        params.coreFocusShortDesc = coreFocusShortDesc
      }
      clearErrors()
      console.log('Search params', params)
      onSearch(params)
    } else {
      console.error('validation failed')
      toast('Validation Failed!', {
        type: 'error',
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      })
    }
  }

  const onReset = () => {
    clearErrors()
    setCustName('')
    setProductYearSeason('')
    setProductDivision('')
    setGeder('')
    setcoreFocusShortDesc('')
    onSearch(undefined)
  }

  return (
    <div>
      <div className="ncss-container fixed-fluid">
        <h3 className="headline-4 page-title text-color-light-grey">
          North America Merch Marketplace Adopt</h3>
        {/* For some reason form submit is not working, so overriding it with custom button and keeping this as dummy. */}
        <form onSubmit={handleSubmit()}>
          <div className="ncss-row Drop">
            <div className="ncss-col-sm-3 ">
              <label >
                <span>Customer Name</span>
                <span aria-hidden="true" className="required-indicator">*</span>
              </label>
              <Multiselect options={customerOptions}
                id="custName"
                selectionLimit="2"
                isObject={false}
                placeholder="Select (Required)"
                selectedValues ={custName}
                onSelect={e => setCustName(e)}
                onRemove={e => setCustName(e)}
                {...register('custName', { required: 'Please select atleast one Customer Name' })}
              />
              {custName.length === 0 && errors.custName && <div className="text-color-error body-4"><p>{errors.custName.message}</p></div>}
            </div>

            <div className="ncss-col-sm-3">
              <label >
                <span>Product Year Season</span>
                <span aria-hidden="true" className="required-indicator">*</span>
              </label>
              <Multiselect options={productYSOptions}
                id="productYearSeason"
                selectionLimit="4"
                isObject={false}
                placeholder="Select (Required)"
                selectedValues ={productYearSeason}
                onSelect={e => setProductYearSeason(e)}
                onRemove={e => setProductYearSeason(e)}
                {...register('productYearSeason', { required: 'Please select atleast one Produt Year Season' })}
              />
              {productYearSeason.length === 0 && errors.productYearSeason && <div className="text-color-error body-4"><p>{errors.productYearSeason.message}</p></div>}
            </div>

            <div className="ncss-col-sm-3">
              <label >
                <span>Division</span>
                <span aria-hidden="true" className="required-indicator">*</span>
              </label>
              <Multiselect options={divisionOptions}
                id="productDivision"
                isObject={false}
                placeholder="Select (Required)"
                selectedValues ={productDivision}
                onSelect={e => setProductDivision(e)}
                onRemove={e => setProductDivision(e)}
                {...register('productDivision', { required: 'Please select atleast one Produt Division' })}
              />
              {productDivision.length === 0 && errors.productDivision && <div className="text-color-error body-4"><p>{errors.productDivision.message}</p></div>}
            </div>
          </div>
          <div className="ncss-row Drop">
            <div className="ncss-col-sm-3">
              <label >Gender</label>
              <Multiselect options={genderOptions}
                id="gender"

                isObject={false}
                selectedValues ={gender}
                onSelect={e => setGeder(e)}
                onRemove={e => setGeder(e)}
                {...register('gender')}
              />
            </div>
            <div className="ncss-col-sm-3">
              <label >Core Focus</label>
              <Multiselect options={coreFocusOptions}
                id="coreFocusShortDesc"
                isObject={false}
                selectedValues ={coreFocusShortDesc}
                onSelect={e => setcoreFocusShortDesc(e)}
                onRemove={e => setcoreFocusShortDesc(e)}
                {...register('coreFocusShortDesc')}
              />
            </div>
            <div className="ncss-col-sm-3">
              <div className="ncss-row submit-container">
                <div className="ncss-col-sm-offset-2 ncss-col-sm-4">
                  <button className="ncss-btn-secondary-dark" type="reset" onClick={() => onReset()}>Reset</button>
                </div>
                <div className="ncss-col-sm-offset-1 ncss-col-sm-4">
                  <button className="ncss-btn-primary-dark" type="submit" onClick={() => onSubmit()}>Search</button>
                  {/* <button className="ncss-btn-primary-dark" type="submit" >Search</button> */}

                </div>
              </div>

            </div>
          </div>
        </form>
      </div >
      <script src="script.js"></script>
    </div >
  )
}

export default Search
