import React from 'react'

const Generic = ({ title, error }) => {
  return (
    <div className="ncss-container fixed-fluid">
      {title ? <h1> {title}  </h1> : <h1>' An error occured, unable to load page!'</h1>}
      <div>{error ? <pre>{error.message || error}</pre> : null}</div>
    </div>
  )
}

export default Generic
