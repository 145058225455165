import React, { useState, useEffect } from 'react'

import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify'
import http from '../util/http'
import config from '../config'
import { searchFields } from '../constants'

import Spinner from '../components/Spinner'
import Search from '../components/search/Search'
import DataGrid from '../components/grid/DataGrid'

const HomePage = () => {
  const [query, setQuery] = useState()
  const [options, setOptions] = useState({})
  const [headers, setHeaders] = useState([])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    console.log('Invoking metadata call')
    const url = `${config.apiUrl}/${config.metadata}/${config.searchIndex}`
    const query = {
      fields: searchFields,
      shouldTransform: true
    }
    http.get(url, query).then(({ options, fields }) => {
      if (!options || !fields) {
        toast('Failed to load the application!', {
          type: 'error',
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })
      }
      setOptions(options)
      setHeaders(fields)
      setLoading(false)
    }).catch(e => {
      console.error(e)
      toast(e.message, {
        type: 'error',
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      })
      setLoading(false)
    })
  }, [])
  return (
    <div>
      <Search onSearch={setQuery} options={options} ></Search>
      {isLoading && <Spinner large/>}
      <DataGrid query={query} headers= {headers}/>
    </div>
  )
}

export default HomePage
