import React from 'react'
import { ToastContainer, toast } from 'react-toastify'

import { useRoutes, Link } from 'raviger'

import { useAuth, useAuthStore } from '@nike/aegis-auth-react'
import { NavBar } from '@nike/epic-react-ui'

import config from '../config'
import routes from './routes'
import NotFoundPage from './NotFoundPage'

import Spinner from '../components/Spinner'
import { setGlobalHeader } from '../util/http'
import client from '../auth/client'
import Generic from '../components/Error'

const navBarLinks = [{ text: 'Search', path: '/' }, { text: 'About', path: '/about' }]

const App = () => {
  const { loginComplete } = useAuth(client)
  const { accessToken, loginError } = useAuthStore()

  const routeResult = useRoutes(routes) || <NotFoundPage />

  if (loginError) {
    return (
      <div>
        <Generic
          title="Login Failed!"
          error ={loginError} />
        <ToastContainer>
          { toast('Login Failed!', {
            type: 'error',
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          })}</ToastContainer>

      </div>
    )
  }

  if (loginComplete) {
    setGlobalHeader('x-api-key', process.env.REACT_APP_BOOKINGS_API_KEY || '')
    setGlobalHeader('access-control-allow-origin', '*')
    setGlobalHeader('Authorization', 'Bearer ' + accessToken)
  } else {
    return <Spinner large />
  }

  return (
    <div>
      <div>
        <ToastContainer />
        <NavBar
          name={config.appFullName}
          logo="/logo.png"
          rootPath="/"
          routes={navBarLinks}
          RouterLink={Link}
        />
        <div style={{ margin: '1rem' }}>{routeResult}</div>
      </div>
    </div>
  )
}

export default App
