
export const dataPageSize = 500

export const searchFields = [
  'productDivision',
  'gender',
  'custName',
  'productYearSeason',
  'coreFocusShortDesc'
]
