import merge from 'deepmerge'

// TODO: populate this once the domain is ready
const prodUrl = 'xyz.nike.com'

const base = {
  appName: 'na-eda-planner-ui',
  appFullName: 'Planner',
  oAuth: {
    clientId: process.env.REACT_APP_CLIENT_ID || 'nike.niketech.namerchmarketplaceadopt'
  },
  searchIndex: 'bookings',
  metadata: 'aggregate-metadata'
}

const dev = merge(base, {
  apiUrl: 'https://api.na-eda-api-test.nikecloud.com',
  oAuth: {
    redirectUri: process.env.REACT_APP_WEB_URL || window.location.origin,
    qa: true
  }
})

const prod = merge(base, {
  // TODO: populate this once the prod API is ready
  apiUrl: 'Prod API URL',
  oAuth: {
    redirect_uri: prodUrl
  }
})

const config = window.location.origin.includes(prodUrl) ? prod : dev

export default config
