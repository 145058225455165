import React from 'react'
import { Loading } from '@nike/nike-design-system-icons'

const containerStyleLarge = {
  // Contain yourself
  position: 'relative',
  width: '10rem',
  height: '10rem',
  margin: 'auto'
}
const containerStyleSmall = {
  position: 'relative',
  width: '1rem',
  height: '1rem'
}
const Spinner = ({ large, className = '' }) => (
  <div style={large ? containerStyleLarge : containerStyleSmall}>
    <div className={`spinner ${large ? 'spinner-lg' : ''} ${className}`} ><Loading/></div>
  </div>
)

export default Spinner
